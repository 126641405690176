export const URLS = {
    BLOG_API: process.env.REACT_APP_BLOG_API,
    IT: {
        CONTACT_US: "https://www.asritsolutions.com/contactUs"
    },
    TRUCKING: {
        CONTACT_US: "https://www.osrit.com/#"
    }
};

export function getDomain() {
    const url = window.location.hostname;
    const hostname = url.split(".").slice(-2);
    return hostname.length === 1 ? "localhost" : `.${hostname.join(".")}`;
}

export function getHost() {
    const url = window.location.hostname;
    const _hostname = url.split(".").slice(-2);
    const hostname = _hostname[0] || "";
    return hostname === "osrit" ? "TRUCKING" : "IT";
}
export function isOsrit() {
    const url = window.location.hostname;
    const _hostname = url.split(".").slice(-2);
    const hostname = _hostname[0] || "";
    return hostname === "osrit";
}

export const OSRIT_URLS = {
    HOME: "//www.osrit.com/",
    CONTACT_US: "//www.osrit.com/contact-us",
    DISPATCH: "https://osrit.com/dispatch",
    PRICE: "https://osrit.com/pricing",

    TERMS: "//www.osrit.com/terms",
    POLICY: "//www.osrit.com/policy",

    TWITTER: "//twitter.com/OsritSoftware",
    FACEBOOK: "//www.facebook.com/osritTrucking",
    PINTEREST: "//www.pinterest.com/OsritTruckingSoftware",
    REDDIT: "//www.reddit.com/user/osrit_truck_software",
    INSTA: "//www.instagram.com/osrit_trucking_software",
    LINKED_IN: "//www.linkedin.com/"
};

export const ASRIT_URLS = {
    HOME: "//www.asritsolutions.com/",
    SERVICE: "//www.asritsolutions.com/services",
    PORTFOLIO: "//www.asritsolutions.com/portfolio",
    CAREER: "//www.asritsolutions.com/career",
    ABOUT: "//www.asritsolutions.com/about",
    CONTACT_US: "//www.asritsolutions.com/contact-us",

    TERMS: "//www.asritsolutions.com/terms",
    POLICY: "//www.asritsolutions.com/policy",

    TWITTER: "//twitter.com/AsritSolutions",
    FACEBOOK: "//www.facebook.com/Asrit-Solutions-109605270772476/",
    PINTEREST: "//www.pinterest.com/Asrit_Solutions/",
    REDDIT: "//www.reddit.com/user/AsritSolutions/",
    INSTA: "//www.instagram.com/",
    LINKED_IN: "//www.linkedin.com/"
};
