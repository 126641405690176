import * as TYPES from "./blogActionTypes";

const defaultState = {
    count: 0,
    loading: false,
    categories: [],
    blogs: [],
    blogSelected: {},
    blogComments: []
};

export default function blog(state = defaultState, action) {
    switch (action.type) {
        case TYPES.BLOG_BUSY:
            return {
                ...state,
                loading: action.value
            };
        case TYPES.BLOG_COUNT:
            return {
                ...state,
                count: action.value
            };
        case TYPES.BLOG_CATEGORIES:
            const categories = action.value.filter((e) => e.slug === "all")
            action.value.forEach(element => {
                if (element.slug !== "all") {
                    categories.push(element)
                }
            });
            return {
                ...state,
                categories: categories
            };
        case TYPES.BLOGS:
            return {
                ...state,
                blogs: action.value
            };
        case TYPES.BLOG_SELECTED:
            return {
                ...state,
                blogSelected: action.value
            };
        case TYPES.BLOG_COMMENTS:
            return {
                ...state,
                blogComments: action.value
            };

        default:
            return state;
    }
}
