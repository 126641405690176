import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./blogActionTypes";

export const setBlogBusy = value => ({
    type: TYPES.BLOG_BUSY,
    value
});

export const setBlogCategories = value => ({
    type: TYPES.BLOG_CATEGORIES,
    value
});

export const setBlogCount = value => ({
    type: TYPES.BLOG_COUNT,
    value
});

export const setBlogs = value => ({
    type: TYPES.BLOGS,
    value
});

export const setBlogSel = value => ({
    type: TYPES.BLOG_SELECTED,
    value
});

export const setBlogComments = value => ({
    type: TYPES.BLOG_COMMENTS,
    value
});

export function onSubscribe(params = {}) {
    return async (dispatch, getState) => {
        try {
            const response = await axios({
                url: `/subscribe`,
                method: "post",
                data: params
            });
            let _response = _get(response, "data", {});
            return _response;
        } catch (error) {
            const errorCode = _get(error, "response.data.errors[0].code");
            return {
                error: error,
                errorCode
            };
        }
    };
}

export function getBlogCategories(params = {}) {
    return async (dispatch, getState) => {
        try {
            const response = await axios({
                url: `/category`,
                method: "get",
                params: params
            });
            let _response = _get(response, "data", []);
            dispatch(setBlogCategories(_response));
            return _response;
        } catch (error) {
            return error;
        }
    };
}

export function getBlogCount(categoryId, params = {}) {
    return async (dispatch, getState) => {
        try {
            const response = await axios({
                url: `/category/${categoryId}/blogs/count`,
                method: "get",
                params: params
            });
            let _response = _get(response, "data.count", 0);
            dispatch(setBlogCount(_response));
            return _response;
        } catch (error) {
            return error;
        }
    };
}

export function getBlogs(categoryId, params = {}) {
    return async (dispatch, getState) => {
        try {
            const response = await axios({
                url: `/category/${categoryId}/blogs`,
                method: "get",
                params: params
            });
            let _response = _get(response, "data", []);
            dispatch(setBlogs(_response));
            return _response;
        } catch (error) {
            return error;
        }
    };
}

export function getBlogById(categoryId, blogId, params = {}) {
    return async (dispatch, getState) => {
        try {
            const response = await axios({
                url: `/category/${categoryId}/blogs/${blogId}`,
                method: "get",
                params: params
            });
            let _response = _get(response, "data", []);
            dispatch(setBlogSel(_response));
            return _response;
        } catch (error) {
            return error;
        }
    };
}

export function createComment(categoryId, blogId, params = {}) {
    return async (dispatch, getState) => {
        try {
            const response = await axios({
                url: `/category/${categoryId}/blogs/${blogId}/comment`,
                method: "post",
                data: params
            });
            let _response = _get(response, "data", {});
            return _response;
        } catch (error) {
            const errorCode = _get(error, "response.data.errors[0].code");
            return {
                error: error,
                errorCode
            };
        }
    };
}

export function getBlogComments(categoryId, blogId, params = {}) {
    return async (dispatch, getState) => {
        try {
            const response = await axios({
                url: `/category/${categoryId}/blogs/${blogId}/comment`,
                method: "get",
                params: params
            });
            let _response = _get(response, "data", []);
            dispatch(setBlogComments(_response));
            return _response;
        } catch (error) {
            return error;
        }
    };
}
