import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

const RecaptchaVerify = props => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    return (
        <Modal
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                    <h4 className="mt-2 mb-4 text-center">
                        VERIFY YOUR IDENTITY
                    </h4>
                    <ReCAPTCHA
                        sitekey={props.siteKey}
                        onChange={value => props.onDone(value)}
                    />
                </div>
                <div className="text-center">
                    <button onClick={props.handleClose}>{"CANCEL"}</button>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        siteKey: _get(state, "appData.recaptchaSiteKey", {})
    };
};

export default connect(mapStateToProps)(RecaptchaVerify);
